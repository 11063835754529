@import 'abstracts/vars';
@import 'abstracts/mixins';
.content,
.rich-text {
	border: $rich-content-border;
	background: $rich-content-bg;
	color: $text-basic;
	font-size: 2em;
	line-height: 1.5;
	overflow: hidden; // * {
	//   max-width: 100%;
	// }
	@import "C:/Projects/Mirvac Residential/src/UI/OandI/OandI Base Site/sass/base/richtext/_richtext-files-icons.scss";
@import "C:/Projects/Mirvac Residential/src/UI/OandI/OandI Base Site/sass/base/richtext/_richtext.scss";


	@include max-screen(sm) {
		font-size: 1.6em;
	}

	p:first-child {
		margin-top: 2em;
	}
}

.content {
	@import "C:/Projects/Mirvac Residential/src/UI/OandI/OandI Base Site/sass/variants/page-content/default.scss";

}

.column-splitter {
	.rich-text {
		&.indent-rich-text {
			padding-left: 21%;
            padding-right: 21%;

            @include max-screen(lg) {
                padding: 2em 1em;
            }
		}
	}
}

.rich-text {
	&.white-text {
		* {
			color: $text-white;
		}
		.btn.btn-link{
			color: $text-white;

			&:after {
			background-color: $text-white;
		}
	}
	}
	&.indent-rich-text {
		padding-left: 30%;
        padding-right: 30%;

        @include max-screen(md) {
            padding-left: 5%;
            padding-right: 5%;
        }
    }

    p{
        &.large{
            font-size: 1.8em;
            font-family: $FrankRuhlLibre;
            font-weight: 500;
            line-height: 1.6em;
            color: $text-black;
            margin-bottom: em(2em, 2em);
        }
    }
}

.magazine {
	display: table;
  height: 60vh;
	font-size: 2em;
	min-height: 36em;

	@include max-screen(lg) {
		min-height: auto;
		height: auto;
	}

	&.rich-text {
		.component-content{
			display: table-cell;
			vertical-align: middle;
		}
	}

	&.component{
		@include max-screen(sm) {
			min-height: auto;
			padding-top: 0;
		}
	}

	h1{
		font-family: $Oswald;
		font-size: em(8.4em, 2em);
		text-transform: uppercase;
		line-height: 1em;
		margin-bottom: 0.5em;

	}

	h3 {
		font-family: $FrankRuhlLibre;
		text-transform: none;
		font-size: 2.6em;
		font-weight: 500;
		line-height: 1.2em;
			@include max-screen(sm){
				font-size: 1.6em;
			}
		}
	p {
		line-height: 1.7em;
		color: $cl__charcoal;
	}
}

.three-column {
	img {
		margin-bottom:1.1em;
	}

	a.btn.btn-link{
		padding-top: 0
	}

}

@import "C:/Projects/Mirvac Residential/src/UI/OandI/OandI Base Site/sass/styles/richtext/rich-text-lists.scss";
@import "C:/Projects/Mirvac Residential/src/UI/OandI/OandI Base Site/sass/styles/richtext/text-white.scss";

