@import '../../abstracts/mixins';

/* Global RTE Typographic Styles for all office asset sites — CH */

h2, h3 {
	margin-top: rem(140px);
	margin-bottom: rem(70px);

	@include max-screen(sm) {
		margin-top: rem(100px);
		margin-bottom: rem(50px);
	}
}

h4 {
	margin-top: rem(100px);
	margin-bottom: rem(70px);

	@include max-screen(sm) {
		margin-top: rem(70px);
		margin-bottom: rem(50px);
	}
}

p {
	margin-bottom: rem(50px);
}

strong {
	font-weight: 700;
}

ol, ul {
	margin-bottom: rem(70px);
	margin-left: rem(50px);

	li + li {
		margin-top: rem(40px);
	}

	@include max-screen(sm) {
		margin-left: 0;
	}
}

ol {
	li {
		margin-left: rem(25px);
		padding-left: rem(12px);
		list-style: decimal;
		list-style-position: outside;
	}
}

ul {
	li {
		position: relative;
		margin: 0;
		padding-left: rem(37px);

		&:before {
			display: block;
			position: absolute;
			top: rem(15px);
			left: 0;
			width: rem(17px);
			height: rem(2px);
			background-color: #977b59;
			content: '';
		}
	}
}

a {
	color: $text-basic;
	text-decoration: underline;

	&:hover {
		color: $text-basic-active;
	}
}

table {
	height: auto !important;
	border: 2px solid $border-gray;

	&, tr, th, td {
		border: solid 2px $border-gray;
		background: $bg-basic-color;
		border-collapse: collapse;
		vertical-align: middle;
	}

	tr, th, td {
		padding: 5px;
	}

	caption {
		margin-left: 0;
		padding: 10px;
		background-color: $bg-light-gray;
		font-size: $font-big;
		font-weight: bold;
		overflow: hidden;
	}

	tr {
		border: 0;
	}

	th, td {
		border-width: 2px 0 0 2px;
	}

	th {
		padding: 7px;
		background-color: lighten($bg-light-gray, 5%);
		font-size: $font-normal;
		font-weight: bold;

		&:first-child {
			border-left: 0;
		}
	}

	td {
		&:first-child {
			border-left: 0;
		}
	}
}

.field-eventstart {
	padding-bottom: rem(19px);
	font-size: 0.8em;
}

blockquote {
	margin-top: rem(140px);
	margin-bottom: rem(140px);
	margin-left: rem(50px);
	font-family: $FrankRuhlLibre;
	font-size: em(3.2em, 2em);
	font-weight: 500;

	@include max-screen(sm) {
		margin-top: rem(100px);
		margin-bottom: rem(100px);
		margin-left: rem(30px);
	}
}

cite {
	font-family: $Roboto;
	font-size: em(1.6em, 3.2em);
	font-weight: normal;

	&:before {
		content: '"';
		font-family: $FrankRuhlLibre;
		line-height: 0.2em;
		font-size: em(6em, 0.8em);
		color: $cl__coral;
		display: block;
		margin-top: em(0.6em, 0.8em)
	}
}

/* Custom WYSIWYG Style Classes - CH */

// Define all font families.
.mv-heading-1,
.mv-heading-2,
.mv-heading-3,
.mv-heading-4,
.mv-heading-5,
.mv-heading-6,
.mv-subheading-5,
.mv-body-1,
.mv-body-5 {
	font-family: 'Oswald', sans-serif;
	font-weight: 500;
	text-transform: uppercase;
}

.mv-heading-7,
.mv-heading-8,
.mv-subheading-1,
.mv-subheading-2,
.mv-subheading-3,
.mv-subheading-4 {
	font-family: 'Frank Ruhl Libre', serif;
	font-weight: 500;
}

/* Headings */

.mv-heading-1 {
	@include font(84px, 84px);

	@include max-screen(sm) {
		font-size: em(38px);
	}
}

.mv-heading-2 {
	@include font(72px, 72px);
}

.mv-heading-3 {
	@include font(52px, 52px);
}

.mv-heading-4 {
	@include font(48px, 48px);
}

.mv-heading-5 {
	@include font(38px, 38px);
}

.mv-heading-6 {
	@include font(32px, 32px);
}

.mv-heading-7 {
	@include font(72px, 77px);
}

.mv-heading-8 {
	@include font(52px, 65px);
}

/* Subheadings */

.mv-subheading-1 {
	@include font(38px, 57px);

	@include max-screen(sm) {
		@include font(32px, 40px);
	}
}

.mv-subheading-2 {
	display: block;
	padding: 1.7em 0;
	@include font(32px, 40px);

	@include max-screen(sm) {
		@include font(24px, 24px);
	}
}

.mv-subheading-3 {
	display: block;
	margin-top: em(53.28px, 24px);
	color: $cl__black;
	@include font(24px, 24px);
}

.mv-subheading-4 {
	@include font(20px, 40px);
}

.mv-subheading-5 {
	@include font(16px, 16px);
}

/* Body Copy */

.mv-body-1 {
	@include font(24px, 24px);

	@include max-screen(sm) {
		@include font(24px, 36px);
	}
}

.mv-body-2 {
	color: $cl__jumbo;
	@include font(24px, 36px, 400);

	@include max-screen(sm) {
		@include font(20px, 30px);
	}
}

.mv-body-3 {
	@include font(20px, 34px);

	@include max-screen(sm) {
		@include font(16px, 16px);
	}
}

.mv-body-3--bold {
	@include font(20px, 30px, 500);

	@include max-screen(sm) {
		@include font(16px, 16px);
	}
}

.mv-body-4 {
	@include font(16px, 16px);
}

.mv-body-4--bold {
	@include font(16px, 16px, 700);
}

.mv-body-5 {
	@include font(20px, 20px);
}
