/**
 * Grid Variables - CH
 */

// Grid Breakpoints (custom extended Bootstrap 4 grid) //

$min-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1470px,
  xxxl: 1630px
) !default;

$max-breakpoints: (
  xs: 0,
  sm: 575px,
  md: 767px,
  lg: 991px,
  xl: 1199px,
  xxl: 1469px,
  xxxl: 1629px
) !default;

// Max Container Widths for each breakpoint //

$container-widths: (
  xs: 100%,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
  xxxl: 1628px
) !default;

// REM Base //

$rem-base: 10px;
