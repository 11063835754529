
////
/// Mirvac Office & Industrial
/// SASS Functions
/// @group Mirvac_OI Config
/// @author Charles Harwood
////

/// Convert units to `rem` based on passed values.
/// @param {length} $value - The input value to convert
/// @param {length} $base-value [$rem-base] - Our base REM value (i.e. this = 1rem)
@function convert-to-rem($value, $base-value: $rem-base) {
    $value: strip-unit($value) / strip-unit($base-value) * 1rem; // Disable ZeroUnit linter as we need to check the exact value including units.
    // sass-lint:disable zero-unit
    @if ($value==0rem) {
      $value: 0;
    } // Turn 0rem into 0
    @return $value;
  }
  
  /// Strip the unit of measurement and return it.
  /// @param {length} $num - The value to strip units from
  @function strip-unit($num) {
    @return $num / ($num * 0 + 1);
  }
  
  /// Return REM units from passed values.
  /// @param {length} $values - One or multiple values to convert to REM
  /// @param {length} $base-value [$rem-base] - Our base REM value (i.e. this = 1rem)
  @function rem($values, $base-value: $rem-base) {
    $max: length($values);
    @if $max==1 {
      @return convert-to-rem(nth($values, 1), $base-value);
    }
    $rem-values: ();
    @for $i from 1 through $max {
      $rem-values: append($rem-values, convert-to-rem(nth($values, $i), $base-value));
    }
    @return $rem-values;
  }
  
  /// Return EM units from passed values.
  /// @param {length} $values - One or multiple values to convert to EM
  /// @param {length} $base-value [$rem-base] - Our base REM value (i.e. this = 1rem)
  @function em($values, $base-value: 16px) {
    $rem-values: rem($values, $base-value);
    $max: length($rem-values);
    @if $max==1 {
      @return strip-unit(nth($rem-values, 1)) * 1em;
    }
    $em-values: ();
    @for $i from 1 through $max {
      $em-values: append($em-values, strip-unit(nth($rem-values, $i)) * 1em);
    }
    @return $em-values;
  }
  
  @function breakpoint-next($name, $breakpoints: $min-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
  }
  
  @function breakpoint-min($name, $breakpoints: $min-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min !=0, $min, null);
  }
  
  @function breakpoint-max($name, $breakpoints: $max-breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints), null);
  }
  
  @function breakpoint-infix($name, $breakpoints: $min-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints)==null, '', '-#{$name}');
  }
  