/* FONT PATH
 * -------------------------- */

@font-face {
    font-family: 'FontAwesome';
    src: url('#{$fa-font-path}/fontawesome-webfont-eot.eot?v=#{$fa-version}') format('opentype');
    src: url('#{$fa-font-path}/fontawesome-webfont-eot.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'), url('#{$fa-font-path}/fontawesome-webfont-woff2.woff2?v=#{$fa-version}') format('woff2'), url('#{$fa-font-path}/fontawesome-webfont-woff.woff?v=#{$fa-version}') format('woff'), url('#{$fa-font-path}/fontawesome-webfont-ttf.ttf?v=#{$fa-version}') format('truetype'), url('#{$fa-font-path}/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
    //  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
    font-weight: normal;
    font-style: normal;
}
