////
/// Mirvac Office & Industrial
/// SASS Mixins
/// @group Mirvac_OI Config
/// @author Charles Harwood
////

@import 'vars';
@import 'functions';

/**
 * Custom SASS Mixins - CH
 */

/// Transition Mixin.
/// Can pass in transition-property, transition-duration and transition-timing-function values,
/// or include without params to use default values.
/// @param {string} $property [all] - The CSS property to apply our transition to
/// @param {time} $duration [0.3s] - How long we want our transition to take to complete
/// @param {string} $timing-function [ease] - The timing function or cubic bezier curve to use for our transition
@mixin transition($property: all, $duration: 0.3s, $timing-function: ease) {
  transition: $property $duration $timing-function;
}

/// Font Styling Mixin.
/// Can pass in font-size, font-weight, line-height, letter-spacing,
/// or include without params to use default values.
/// @param {length} $size [$rem-base] - The value for `font-size` (will be converted to EMs)
/// @param {length} $lheight [null] - The value for `line-height` (will be converted to EMs)
/// @param {integer} $weight [null] - The value for `font-weight` (e.g. 300, 400, etc)
/// @param {length} $lspacing [null] - The value for `letter-spacing` (no conversion)
/// @requires {variable} $rem-base - The default value for `font-size`
@mixin font($size: $rem-base, $lheight: null, $weight: null, $lspacing: null) {
  @if $size !=null {
    font-size: em($size);
  }
  @if $weight !=null {
    font-weight: $weight;
  }
  @if $lheight !=null {
    line-height: em($lheight, $size);
  }
  @if $lspacing !=null {
    letter-spacing: $lspacing;
  }
}

/// Font Styling Mixin - REM variant.
/// Can pass in font-size, font-weight, line-height, letter-spacing,
/// or include without params to use default values.
/// @param {length} $size [$rem-base] - The value for `font-size` (will be converted to REMs)
/// @param {length} $lheight [null] - The value for `line-height` (will be converted to REMs)
/// @param {integer} $weight [null] - The value for `font-weight` (e.g. 300, 400, etc)
/// @param {length} $lspacing [null] - The value for `letter-spacing` (no conversion)
/// @requires {variable} $rem-base - The default value for `font-size`
@mixin font-rem($size: $rem-base, $lheight: null, $weight: null, $lspacing: null) {
  @if $size !=null {
    font-size: rem($size);
  }
  @if $weight !=null {
    font-weight: $weight;
  }
  @if $lheight !=null {
    line-height: em($lheight, $size);
  }
  @if $lspacing !=null {
    letter-spacing: $lspacing;
  }
}

/// Cover Mixin.
/// Position an element to completely cover/fill its next relatively-positioned parent in the DOM tree.
/// @param {integer} $zindex - The value for the `z-index` property
@mixin cover($zindex) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: $zindex;
}

/// Vertical Alignment Mixin.
/// Position an element in the vertical center of its next relatively-positioned parent in the DOM tree.
/// @param {string} $position [relative] - The value for the `position` property (either relative or absolute)
@mixin va($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

/// Center Alignment Mixin.
/// Position an element in the vertical and horizontal center of its next relatively-positioned parent in the DOM tree.
/// @param {string} $position [relative] - The value for the `position` property (either relative or absolute)
@mixin position-center($position: relative) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/// Min-Width Media Query Mixin.
/// Creates a media query with a `min-width` rule.
/// @param {length} $name - The name of the desired breakpoint
/// @param {array}  $breakpoints [$min-breakpoints] - The array of breakpoints
@mixin min-screen($name, $breakpoints: $min-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

/// Max-Width Media Query Mixin.
/// Creates a media query with a `max-width` rule.
/// @param {length} $name - The name of the desired breakpoint
/// @param {array}  $breakpoints [$max-breakpoints] - The array of breakpoints
@mixin max-screen($name, $breakpoints: $max-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

/// Full Media Query Mixin.
/// Creates a media query with both a `min-width` and `max-width` rule.
/// @param {length} $lower - The `min-width` breakpoint.
/// @param {length} $upper - The `max-width` breakpoint.
/// @param {array}  $lower-breakpoints [$min-breakpoints] - The array of `min-width` breakpoints.
/// @param {array}  $upper-breakpoints [$max-breakpoints] - The array of `max-width` breakpoints.
@mixin screen($lower, $upper, $lower-breakpoints: $min-breakpoints, $upper-breakpoints: $max-breakpoints) {
  $min: breakpoint-min($lower, $lower-breakpoints);
  $max: breakpoint-max($upper, $upper-breakpoints);
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Check if the max-width value for this breakpoint is a percentile value rather than pixel value.
      // If true, we don't want to try and convert that value to REM units.
      @if (str-index(inspect($container-max-width), '%')) {
        max-width: $container-max-width;
      } @else {
        max-width: rem($container-max-width);
      }
    }
  }
}

// sass-lint:disable-all
// SXA default mixins.
// Man, these are some incredibly ugly mixins...
// I would prefer if we didn't use any of these - CH.

$break-desktop: 992px;
$break-mobile: 576px;
$break-mobile-horizontal: 640px;
$break-mobile-large: 786px;

@mixin wrapper() {
  max-width: 770px;
  margin: 0 auto !important;
}

@mixin clearfix() {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin font-size($sizeValue: 1.6) {
  font-size: $sizeValue * 10 + px;
  font-size: $sizeValue + rem;
}

@mixin opensans-font-stack() {
  font-family: 'Roboto', Helvetica, Verdana, Tahoma, sans-serif;
}

@mixin loading-gif() {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJibGFjayI+DQogIDxwYXRoICBvcGFjaXR5PSIuMjUiIGQ9Ik0xNiAwIEExNiAxNiAwIDAgMCAxNiAzMiBBMTYgMTYgMCAwIDAgMTYgMCBNMTYgNCBBMTIgMTIgMCAwIDEgMTYgMjggQTEyIDEyIDAgMCAxIDE2IDQiLz4NCiAgPHBhdGggZmlsbD0nIzFhODBiNicgZD0iTTE2IDAgQTE2IDE2IDAgMCAxIDMyIDE2IEwyOCAxNiBBMTIgMTIgMCAwIDAgMTYgNHoiPg0KICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDE2IDE2IiB0bz0iMzYwIDE2IDE2IiBkdXI9IjAuOHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPg0KICA8L3BhdGg+DQo8L3N2Zz4NCg==);
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: $break-mobile) {
      @content;
    }
  }
  @else if $media == mobile-horizontal {
    @media only screen and (max-width: $break-mobile-horizontal - 1) {
      @content;
    }
  }
  @else if $media == mobile-large {
    @media only screen and (max-width: $break-mobile-large) {
      @content;
    }
  }
  @else if $media == tablet {
    @media only screen and (min-width: $break-mobile + 1) and (max-width: $break-desktop - 1) {
      @content;
    }
  }
  @else if $media == all-mobile {
    @media only screen and (max-width: $break-desktop - 1) {
      @content;
    }
  }
  @else if $media == desktop {
    @media only screen and (min-width: $break-desktop) {
      @content;
    }
  }
}

@mixin border-basic($position: all, $border-color: $border-gray, $border-width: 1px) {
  @if $position == top {
    border-top: $border-width solid $border-color;
  }
  @else if $position == bottom {
    border-bottom: $border-width solid $border-color;
  }
  @else if $position == left {
    border-left: $border-width solid $border-color;
  }
  @else if $position == right {
    border-right: $border-width solid $border-color;
  }
  @else {
    border: $border-width solid $border-color;
  }
}

@mixin fixed-bg($pos, $min-height: 240px) {
  background-position: $pos;
  background-attachment: fixed;
  min-height: $min-height;
}

@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin fullscreen-app {
  height: calc(100vh - 163px);
}
@mixin placeholder($color: $cl__charcoal) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // See https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}
@mixin state-disabled() {
  box-shadow: none;
  cursor: not-allowed;
}
