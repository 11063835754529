$font-small:11px;
$font-normal:13px;
$font-medium:16px;
$font-big:20px;
$font-extrabig:24px;
//Navigation
$navigation-font-basic:18px;
$navigation-font-basic-submenu:14px;
//Tabs
$tab-font-header:16px;
//Accordion
$accordion-header:16px;
//Breadcrumb
$breadcrumb-font:15px;
// Link List
$link-list-title-font:15px;
