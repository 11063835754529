@import "../fontawesome/font-awesome";
@import "../../abstracts/mixins";
//files icons
.link, .media-link, .file-list {
    .field-title, .field-linktext {
        display: inline-block;
        line-height: 21px;
    }
    span {
        @include font-size(1.2);
    }
    a {
        text-decoration: none;
    }
}
.pdf, .xls, .xslx, .pptx, .ppt, .docx, .doc, .bmp, .png, .jpg, .jpeg, .psd, .gif, .avi, .mp4, .wmv, .mov, .mp3, .wma, .txt, .zip {
    display: inline-block;
    box-sizing: border-box;
    font-weight: normal;
    width: 15px;
    height: 21px;
    margin: 0;
    padding: 0;
    position: relative;
    &:before {
        @include font-size(1.6);
        @include fa-icon();
        position: absolute;
        display: block;
        top: 0;
        margin: 0;
        padding: 0;
    }
}
.pdf:before {
    content: $fa-var-file-pdf-o;
}
.xlsx, .xls {
    &:before {
        content: $fa-var-file-excel-o;
    }
}
.pptx, .ppt {
    &:before {
        content: $fa-var-file-powerpoint-o;
    }
}
.docx, .doc {
    &:before {
        content: $fa-var-file-word-o;
    }
}
.bmp, .png, .jpg, .jpeg, .psd, .gif {
    &:before {
        content: $fa-var-file-image-o;
    }
}
.avi, .mp4, .wmv, .mov {
    &:before {
        content: $fa-var-file-video-o;
    }
}
.mp3, .wma {
    &:before {
        content: $fa-var-file-audio-o;
    }
}
.txt:before {
    content: $fa-var-file-text-o;
}
.zip:before {
    content: $fa-var-file-archive-o;
}
